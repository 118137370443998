
.languageFlag {
  cursor: pointer !important;
  opacity: 0.6;
  line-height: 1.1;

  &:hover {
    opacity: 0.8;
  }
}

.selected {
  opacity: 1;
  cursor: default;
}