#root, body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.capitalize {
  text-transform: capitalize;
}
.text-center {
  text-align: center;
}
.bold {
  font-weight: 600 !important;
}